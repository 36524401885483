import { useEffect, useState } from "react";
import { Box,CircularProgress } from '@mui/material';
import { ErrorHeader } from "../../shared/errorHeader";
import { AthleteEducation, AthleteExperience, AthleteInfo} from "../../api/models/athlete";
import { useParams } from "react-router-dom";
import { getAthlete} from "../../api/athleteApi";

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { formatHeight } from "../../utils/dataFormatters";

dayjs.extend(duration);


export function AthletePage() {

    const [athlete, setAthlete] = useState<AthleteInfo | undefined>(undefined);
    const [experienceRows, setExperienceRows] = useState<RowItem[] | undefined>(undefined);
    const [educationRows, setEducationRows] = useState<RowItem[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();

    const birthDate = dayjs(athlete?.birthDate);
    const age = dayjs().diff(birthDate, 'year');

    useEffect(() => {
        initPage();
    }, []);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.athleteGuid) {
                throw Error('Athlete GUID is missing');
            }

            await refreshMember(params.athleteGuid);
            setIsLoading(false);
        } 
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    function mapExperienceToRows(responseExperience?: AthleteExperience[]) {
        if (responseExperience && responseExperience.length > 0) {
            const tempRows = responseExperience.map((exp) => ({
                id: exp.startDate.toString(),
                items: [{ name: 'date', value:dayjs(exp.startDate).format('MM/YY') }, 
                        { name: 'org', value: exp.orgName },
                        { name: 'team', value: `${exp.seasonName} - ${exp.level}` },
                    ]
            }));

            setExperienceRows(tempRows);
        }
    }

    function mapEducationToRows(responseEducation?: AthleteEducation[]) {
        if (responseEducation && responseEducation.length > 0) {
            const tempRows = responseEducation.map((education) => ({
                id: education.startDate.toString(),
                items: [{ name: 'date', value:dayjs(education.startDate).format('MM/YY') }, 
                        { name: 'school', value: education.schoolName },
                        { name: 'grade level', value: `${education.schoolGrade}` },
                        { name: 'gpa', value: `${education.gpa}` },
                    ]
            }));

            setEducationRows(tempRows);
        }
    }

    async function refreshMember(athleteGuid: string) {
        const response = await getAthlete(athleteGuid);
        setAthlete(response);
        if (response.experiences) {
            mapExperienceToRows(response.experiences);
        }
        if (response.education) {
            mapEducationToRows(response.education);
        }
    }

    const mainContent = () => {

        return (
            
                <Box>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={athlete?.orgSeason?.logoLink} height={50} alt='profile' />
                        {athlete?.orgSeason && <h2 style={{ margin: '0 10px' }}>{athlete?.orgSeason.name}</h2>}
                    </div>

                    <h1>{athlete?.firstName}  {athlete?.lastName} </h1>
                    {<img src={athlete?.profileLink} height={200} alt='profile' />}
                    {athlete?.team && <h3 style={{ margin: '0 10px' }}>{athlete?.team.name} - #{athlete?.jerseyNumber}</h3>}
                    

                    <br />
                    
                    <h2>Bio</h2>
                    <table>
      <tbody>
        <tr>
          <td>Height:</td>
          <td><strong>{formatHeight(athlete?.height)}</strong></td>
        </tr>
        <tr>
          <td>Weight:</td>
          <td><strong>{athlete?.weight}</strong></td>
        </tr>
        <tr>
          <td>Birth Date:</td>
          <td><strong>{birthDate.format('MM/DD/YY')}</strong></td>
        </tr>
        <tr>
          <td>Age:</td>
          <td><strong>{age}</strong></td>
        </tr>
      </tbody>
    </table>


                    <h2>Experience</h2>
                    {experienceRows && <MiniGrid rows={experienceRows} width={500} />}

                    <br/><br/>

                    <h2>Videos</h2>
                    {athlete?.videos && athlete?.videos.map((video) => (
                        <div key={video.videoLink}>
                            <a href={video.videoLink} target="_blank" rel="noopener noreferrer">
                            <img src={video.thumbnailLink} alt={video.title} style={{ width: '150px', height: 'auto' }} />
                            <p>{video.title}</p>
                            </a>
                        </div>
                    ))}

                    <br/><br/>

                    <h2>Education</h2>
                    {educationRows && <MiniGrid rows={educationRows} width={500} />}
                    <p>Transcripts available upon request</p>

                    <br/><br/>

                    {athlete?.qrCodeLink && <img src={athlete?.qrCodeLink} height={200} alt='profile' />} 
                   

                    <br />

                </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {athlete && mainContent()}
    </Box>
    );

}